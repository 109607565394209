
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';
import { envSettingsMailSupport } from '@/utils/env';

/**
 * Component that shows MQTT info settings tab
 */
@Component
export default class MQTT extends Vue {
  @State('projects') projectsState!: IProjectsState;

  shownSupportMail = envSettingsMailSupport;

  get userName() {
    return this.projectsState.project.id;
  }

  get userPassword() {
    return this.projectsState.project.secret ?? '';
  }

  get mqttInfoObject() {
    const obj: any = {
      ...this.projectsState.mqttInfo,
    };
    obj.username = this.userName;
    obj.password = this.userPassword;
    return obj;
  }
}
